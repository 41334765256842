import React, { useState } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { loadStripe } from '@stripe/stripe-js';
import { Star, Filter } from 'lucide-react';
import posthog from 'posthog-js';
import Modal from '@/components/Modals/Modal';
import axiosClient from '@/utils/axiosClient';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

interface MembershipModalProps {
  triggerFeature: 'favorite' | 'filter';
}

const MembershipModal = NiceModal.create(({ triggerFeature }: MembershipModalProps) => {
  const modal = useModal('membership-modal');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubscribe = async () => {
    setIsLoading(true);
    setError(null);
    try {
      localStorage.setItem('previousUrl', window.location.pathname + window.location.search);

      const response = await axiosClient.post('/v1/memberships');
      if (response?.data?.stripe_session_id) {
        const stripe = await stripePromise;
        if (stripe) {
          posthog.capture('checkout_subscription', { product: 'v1_50for35' });
          const result = await stripe.redirectToCheckout({
            sessionId: response.data.stripe_session_id,
          });
          if (result.error) {
            throw new Error(result.error.message);
          }
        } else {
          throw new Error('Failed to load Stripe');
        }
      } else {
        throw new Error('No Stripe session ID received');
      }
    } catch (error) {
      console.error('Subscription error:', error);
      setError(error instanceof Error ? error.message : 'Something went wrong. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const featureInfo = {
    favorite: {
      icon: <Star className="w-16 h-16 text-brand-purple" />,
      title: 'Favorite Pharmacies and More!',
      description:
        "You've discovered our favoriting feature! This and other premium tools are available with our monthly membership.",
    },
    filter: {
      icon: <Filter className="w-16 h-16 text-brand-purple" />,
      title: 'Advanced Filtering and More!',
      description:
        "You've tried to use our advanced filtering feature! This and other premium tools are available with our monthly membership.",
    },
  };

  const { icon, title, description } = featureInfo[triggerFeature];

  return (
    <Modal modal={modal} title="Unlock Premium Features" description="Enhance your pharmacy search experience" wide>
      <div className="flex flex-col items-center space-y-6 p-4">
        {icon}
        <h2 className="text-2xl font-bold text-center">{title}</h2>
        <p className="text-center text-gray-600 max-w-md">{description}</p>
        <div className="bg-gray-100 p-4 rounded-lg w-full max-w-md">
          <h3 className="font-semibold text-brand-purple mb-2">Membership Advantages:</h3>
          <ul className="list-disc list-inside text-sm space-y-1">
            <li>Favorite and quickly access your preferred pharmacies</li>
            <li>50 pharmacy calls included monthly</li>
            <li>Unused credits roll over - never lose what you don't use!</li>
            <li>Priority access to new features as they're released</li>
            <li>Exclusive discounts on additional credits</li>
          </ul>
        </div>
        <div className="text-center">
          <p className="text-xl font-bold mb-2">Just $35/month</p>
          <p className="text-sm text-gray-500">Flexible subscription - cancel anytime</p>
        </div>
        <button
          onClick={handleSubscribe}
          disabled={isLoading}
          className={`w-full max-w-md px-6 py-3 text-lg font-semibold text-white transition-colors rounded-full ${
            isLoading ? 'bg-gray-400 cursor-not-allowed' : 'bg-brand-purple hover:bg-purple-700'
          }`}
        >
          {isLoading ? 'Processing...' : 'Start Your Premium Experience'}
        </button>
        {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
        <p className="text-sm text-gray-600 text-center max-w-md">
          Join now and immediately unlock all our premium features, including favoriting, filtering, and more.
          Streamline your searches and save time with Needle's enhanced tools.
        </p>
      </div>
    </Modal>
  );
});

export default MembershipModal;
