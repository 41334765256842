import React, { useState, memo } from 'react';
import { useModal } from '@ebay/nice-modal-react';
import { ArrowLeft, Edit2Icon, PlusIcon, Search, X } from 'lucide-react';
import { Link } from 'react-router-dom';
import MedIcon from '@/components/MedIcon';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import useMedications from '@/hooks/useMedications';
import { IMedication } from '@/types';

interface MedicationCardProps {
  medication: IMedication;
  onEdit: (medication: IMedication) => void;
}

const MedicationCard = memo(({ medication, onEdit }: MedicationCardProps) => {
  return (
    <Card className="flex flex-col h-full transition-all duration-300 shadow-md hover:shadow-lg">
      <div className="flex items-center p-4 rounded-t-lg bg-gray-50">
        <div className="w-10 h-10">
          <MedIcon medication={medication} />
        </div>
        <div className="flex-grow ml-3 overflow-hidden">
          <h3 className="text-lg font-semibold leading-tight truncate">{medication.medication}</h3>
          {medication.label && (
            <Badge variant="default" className="max-w-full mt-1 truncate">
              {medication.label}
            </Badge>
          )}
        </div>
      </div>
      <div className="flex-grow p-4 space-y-2 text-sm">
        <p>
          <span className="font-medium">Variant:</span> {medication.variant || 'N/A'}
        </p>
        <p>
          <span className="font-medium">Dosage:</span> {medication.dosage || 'N/A'}
        </p>
        <p>
          <span className="font-medium">Form:</span> {medication.dosage_form || 'N/A'}
        </p>
        <p>
          <span className="font-medium">Quantity:</span> {medication.quantity || 'N/A'}
        </p>
        {medication.note && (
          <div className="p-2 mt-2 rounded-md bg-gray-50">
            <span className="font-medium">Note:</span>
            <p className="break-words">{medication.note}</p>
          </div>
        )}
      </div>
      <div className="flex justify-end p-2 space-x-2 rounded-b-lg bg-gray-50">
        <Button variant="outline" size="sm" onClick={() => onEdit(medication)} className="flex items-center">
          <Edit2Icon className="w-4 h-4" />
          <span className="hidden ml-2 lg:inline">Edit</span>
        </Button>
        <Button size="sm" asChild className="flex items-center">
          <Link to={`/requests/new?medication_id=${medication.id}`}>
            <Search className="w-4 h-4" />
            <span className="hidden ml-2 lg:inline">Search</span>
          </Link>
        </Button>
      </div>
    </Card>
  );
});

const Medications: React.FC = () => {
  const medications = useMedications();
  const medicationModal = useModal('medication-modal');
  const [search, setSearch] = useState('');

  const sortedMedications = medications.data
    ? medications.data.filter((med) => !med.archived_at).sort((a, b) => a.medication.localeCompare(b.medication))
    : [];

  const filteredMedications = sortedMedications.filter((medication) => {
    const searchLower = search.toLowerCase();
    return (
      (medication.medication?.toLowerCase().includes(searchLower) ?? false) ||
      (medication.dosage?.toLowerCase().includes(searchLower) ?? false) ||
      (medication.dosage_form?.toLowerCase().includes(searchLower) ?? false) ||
      (medication.label?.toLowerCase().includes(searchLower) ?? false) ||
      (medication.note?.toLowerCase().includes(searchLower) ?? false) ||
      (medication.preference?.toLowerCase().includes(searchLower) ?? false) ||
      (medication.quantity?.toLowerCase().includes(searchLower) ?? false) ||
      (medication.variant?.toLowerCase().includes(searchLower) ?? false)
    );
  });

  const handleEdit = (medication: IMedication) => {
    medicationModal.show({
      request: null,
      medication: medication,
      medications: medications.data,
    });
  };

  return (
    <div className="w-full">
      <div className="flex items-center justify-start gap-1 mt-4 mb-6 sm:mt-2">
        <div className="mr-2 leading-snug text-gray-600 hover:text-violet-600">
          <Link to="/">
            <ArrowLeft />
          </Link>
        </div>
        <div className="font-medium leading-snug text-violet-600">Medication List</div>
      </div>

      <div className="flex flex-col items-start justify-between gap-4 mb-8 md:flex-row md:items-center">
        <div>
          <h2 className="text-3xl font-bold leading-8 text-neutral-800 sm:text-2xl">
            All of your medications in one place!
          </h2>
          <p className="max-w-sm mt-2 text-sm font-normal leading-normal text-gray-500 md:max-w-none">
            Organize your medication list and keep track of your prescriptions with Needle.
          </p>
        </div>
        <Button
          className="items-center gap-2 transition-all duration-300 rounded-full shadow-sm hover:shadow-md"
          onClick={() =>
            medicationModal.show({
              medications: sortedMedications,
              defaultPath: 'newMedication',
            })
          }
        >
          <PlusIcon className="w-6 h-6" />
          Add Medication
        </Button>
      </div>

      <div className="flex items-center w-full mb-8 overflow-hidden bg-white border border-gray-200 rounded-full shadow-sm">
        <Search className="w-5 h-5 ml-4 text-gray-400" />
        <Input
          type="text"
          placeholder="Search medications"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className="flex-grow px-3 py-2 border-none focus:ring-0 focus:outline-none"
        />
        {search.length > 0 && (
          <Button variant="ghost" size="sm" onClick={() => setSearch('')} className="mr-2">
            <X className="w-4 h-4" />
          </Button>
        )}
      </div>

      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {filteredMedications.map((medication) => (
          <MedicationCard key={medication.id} medication={medication} onEdit={handleEdit} />
        ))}
      </div>
      {!filteredMedications.length && (
        <div className="p-8 mt-8 text-center text-gray-500 rounded-lg shadow-inner bg-gray-50">
          <p className="mb-3 text-xl font-medium">
            {search.length > 0 ? 'No medications found' : 'You have not added any medications'}
          </p>
          <p className="mb-4 text-sm">
            {search.length > 0 ? 'Try adjusting your search terms' : 'Click the "Add Medication" button to get started'}
          </p>
          {!search.length && (
            <Button
              className="mt-2"
              onClick={() =>
                medicationModal.show({
                  medications: sortedMedications,
                  defaultPath: 'newMedication',
                })
              }
            >
              <PlusIcon className="w-5 h-5 mr-2" />
              Add Your First Medication
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default Medications;
