import { useState, useEffect } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { MagnifyingGlassIcon } from '@heroicons/react/16/solid';
import { MapPin, Phone } from 'lucide-react';
import { Star } from 'lucide-react';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { StatusIndicator } from '@/components/Requests/StatusIndicator';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import useMe from '@/hooks/useMe';
import { IMedication, IRequest, ITask } from '@/types';
import axiosClient from '@/utils/axiosClient.ts';
import { formatPhoneNumber } from '@/utils/helpers';
import { cn } from '@/utils/utils';

interface PharmacyResultsProps {
  request: IRequest;
  medication: IMedication;
}

const PharmacyResults = ({ request: initialRequest, medication }: PharmacyResultsProps) => {
  const queryClient = useQueryClient();
  const [localRequest, setLocalRequest] = useState<IRequest>(initialRequest);
  const [search, setSearch] = useState('');
  const [medicationFilters, setMedicationFilters] = useState<string[]>([]);

  const currentUser = useMe();

  // Function to determine the default filter
  const determineDefaultFilter = (tasks: ITask[]) => {
    const hasInStock = tasks.some((task) => task.stock_status === 'in_stock');
    const hasOutOfStock = tasks.some((task) => task.stock_status === 'out_of_stock');

    if (hasInStock) return 'In Stock';
    if (hasOutOfStock) return 'Out of Stock';
    return 'All';
  };

  // Initialize filter state using the function
  const [filter, setFilter] = useState(() => determineDefaultFilter(initialRequest.tasks));

  useEffect(() => {
    setLocalRequest(initialRequest);
    setMedicationFilters([medication.id]);
    // Note: We're not setting the filter here anymore
  }, [initialRequest, medication]);

  const tasks = localRequest.tasks.filter((task) => medicationFilters.includes(task.medication_id));

  const filtersAvailable = ['All', 'In Stock', 'Out of Stock'];

  const sortedTasks = tasks.sort((a, b) => {
    const statusOrder = {
      in_stock: 1,
      out_of_stock: 2,
      invalid: 3,
      has_notes: 3,
      other: 6, // Other statuses or null
    };

    const getOrder = (task: ITask) => {
      if (task.stock_status === 'in_stock') return statusOrder.in_stock;
      if (task.stock_status === 'out_of_stock') return statusOrder.out_of_stock;
      if (task.stock_status === 'invalid') return statusOrder.invalid;
      if (task.notes) return statusOrder.has_notes;
      return statusOrder.other;
    };

    const orderA = getOrder(a);
    const orderB = getOrder(b);

    if (orderA !== orderB) {
      return orderA - orderB;
    }

    // If the status order is the same, sort by pharmacy name
    if (a.pharmacy.name < b.pharmacy.name) {
      return -1;
    }
    if (a.pharmacy.name > b.pharmacy.name) {
      return 1;
    }
    return 0;
  });

  const filteredTasks = sortedTasks.filter((task) => task.pharmacy.name.toLowerCase().includes(search.toLowerCase()));

  const filteredTasksByStatus = filteredTasks.filter((task) => {
    if (filter === 'All') {
      return true;
    }

    if (localRequest.status !== 'completed') {
      return false;
    }

    if (task.stock_status === null) {
      return task.status === filter.replace(/ /g, '_').toLowerCase();
    }

    return task.stock_status === filter.replace(/ /g, '_').toLowerCase();
  });

  const handleFilter = (filter: string) => {
    setFilter(filter);
  };

  const toggleMedicationFilter = (medicationId: string) => {
    setMedicationFilters((prev) =>
      prev.includes(medicationId) ? prev.filter((id) => id !== medicationId) : [...prev, medicationId],
    );
  };

  const handleToggleFavorite = (pharmacyId: string) => {
    if (!currentUser?.data?.membership_is_active) {
      NiceModal.show('membership-modal', { triggerFeature: 'favorite' });
      return;
    }

    axiosClient
      .post('/v1/user_favorite_pharmacies/toggle', { pharmacy_id: pharmacyId })
      .then((response) => {
        setLocalRequest((prevRequest) => {
          const updatedTasks = prevRequest.tasks.map((task) =>
            task.pharmacy.id === pharmacyId
              ? { ...task, pharmacy: { ...task.pharmacy, favorited: response.data.favorited } }
              : task,
          );
          const updatedRequest = { ...prevRequest, tasks: updatedTasks };

          // Update the cache
          queryClient.setQueryData([`v1/requests/${updatedRequest.id}`], updatedRequest);

          // Note: We're not changing the filter here
          return updatedRequest;
        });
      })
      .catch((error) => {
        console.error('Error toggling favorite:', error);
      });
  };
  return (
    <>
      <div className="flex items-center justify-between h-10 border border-gray-300 rounded-md">
        <div className="flex items-center justify-between h-full px-2">
          <MagnifyingGlassIcon className="w-5 h-5 text-brand-purple" />
          <Input
            type="text"
            placeholder="Search for a pharmacy"
            value={search ?? ''}
            onChange={(e) => setSearch(String(e.target.value))}
            className="w-full text-sm placeholder-gray-400 bg-transparent border-none focus:ring-0 focus:outline-none disabled:cursor-not-allowed disabled:opacity-50"
          />
        </div>
      </div>

      <div className="mt-4">
        <div className="flex flex-col gap-4 md:flex-row md:items-center">
          {/* Status Filters Section */}
          <div className="flex flex-col gap-2">
            <h3 className="text-base font-medium text-gray-700">Status</h3>
            <div className="flex flex-row flex-wrap gap-2">
              {filtersAvailable.map((f) => (
                <Button
                  variant={'outline'}
                  size="sm"
                  className={cn(
                    'gap-2 rounded-md',
                    f === filter ? 'bg-brand-purple text-white' : 'bg-white text-gray-800',
                  )}
                  key={f}
                  onClick={() => handleFilter(f)}
                >
                  {f} (
                  {
                    filteredTasks.filter((task) => {
                      if (f === 'All') {
                        return true;
                      }

                      if (localRequest.status !== 'completed') {
                        return false;
                      }

                      if (task.stock_status === null) {
                        return task.status === f.replace(/ /g, '_').toLowerCase();
                      }

                      return task.stock_status === f.replace(/ /g, '_').toLowerCase();
                    }).length
                  }
                  )
                </Button>
              ))}
            </div>
          </div>

          {/* Medication Filters Section */}
          <div className="flex flex-col flex-wrap gap-2 ">
            <h3 className="text-base font-medium text-gray-700">Medication(s)</h3>
            <div className="flex flex-row flex-wrap gap-2">
              {localRequest.medications.map((med) => (
                <Button
                  key={med.id}
                  variant={'outline'}
                  size="sm"
                  className={cn(
                    'gap-2 rounded-md',
                    medicationFilters.includes(med.id) ? 'bg-brand-purple text-white' : 'bg-white text-gray-800',
                  )}
                  onClick={() => toggleMedicationFilter(med.id)}
                >
                  {med.medication} {med.dosage} {med.quantity} {med.dosage_form}
                </Button>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          {filteredTasksByStatus.map((task) => {
            const formatGoogleMapsLink = () => {
              const address = `${task.pharmacy.address1} ${task.pharmacy.city} ${task.pharmacy.state} ${task.pharmacy.zip}`;
              const encodedAddress = encodeURIComponent(address);
              return `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
            };

            const mapUrl = formatGoogleMapsLink();

            return (
              <div key={task.id} className="flex flex-col h-full p-4 border border-gray-300 rounded-md">
                <div className="flex items-center justify-between">
                  <div className="text-base font-semibold leading-6">{task.pharmacy.name}</div>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleToggleFavorite(task.pharmacy.id);
                    }}
                  >
                    <Star fill={task.pharmacy.favorited ? 'yellow' : 'none'} />
                  </button>
                </div>
                <div className="text-sm text-gray-600">{task.pharmacy.address1}</div>
                <div className="text-sm text-gray-600">
                  {task.pharmacy.city} {task.pharmacy.state} {task.pharmacy.zip}
                </div>
                <div className="my-1 text-sm text-gray-600">{formatPhoneNumber(task.pharmacy.phone)}</div>

                <div className="flex flex-col flex-grow gap-2 mt-4">
                  {localRequest.medications.map((med) => {
                    const theTask = localRequest.tasks.find(
                      (t) => t.medication_id === med.id && t.pharmacy_id === task.pharmacy.id,
                    );

                    return (
                      <div key={med.id} className="flex flex-col gap-2">
                        <div className="flex items-center justify-between">
                          <div className="text-base font-semibold text-gray-800">
                            {med.medication} {med.dosage}
                            <div className="text-sm font-medium text-gray-500">
                              {med.quantity} {med.dosage_form}
                            </div>
                          </div>

                          <div className="text-sm text-gray-800">
                            {localRequest.status === 'completed' && theTask?.stock_status && (
                              <StatusIndicator status={theTask?.stock_status as any} />
                            )}
                          </div>
                        </div>
                        {localRequest.status === 'completed' && theTask?.notes && (
                          <div className="w-full p-2 bg-gray-100 rounded-md">
                            <div className="text-sm text-gray-800">{theTask.notes}</div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>

                <div className="mt-4">
                  <div className="flex flex-row gap-2 mt-auto">
                    <Button variant={'outline'} className="w-full gap-2 rounded-md" asChild>
                      <Link to={`tel:${formatPhoneNumber(task.pharmacy.phone)}`}>
                        <Phone className="h-4 text-brand-purple" />
                        Call
                      </Link>
                    </Button>
                    <Button variant={'outline'} className="w-full gap-2 rounded-md">
                      <Link to={mapUrl} target="_blank" className="flex items-center gap-2">
                        <MapPin className="h-4 text-brand-purple" />
                        Map
                      </Link>
                    </Button>
                  </div>
                </div>
              </div>
            );
          })}

          {filteredTasksByStatus.length === 0 && (
            <div className="col-span-2 p-4 border border-gray-300 rounded-md">
              <div className="text-base font-semibold leading-6">No pharmacies found with these filters</div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PharmacyResults;
